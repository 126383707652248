.app.logo {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  height: 48px;
  width: 48px;
}
.container {
  display: grid;
  grid-template-columns: 50% 50%;
}
.content {
  margin: 1rem;
  padding: 0.5rem;

  background-color: #F7F7F7;
  border: thin solid #7777;

  -webkit-box-shadow: 10px 10px 5px 0px rgba(238,183,53,1);
  -moz-box-shadow: 10px 10px 5px 0px rgba(238,183,53,1);
  box-shadow: 10px 10px 5px 0px rgba(238,183,53,1);
}
.content a {
  font-size: 0.75rem;
}