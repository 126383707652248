header {
  display:none;
  }
  
  .container {
      display: inline;
      padding: 10px 30px;
      margin: 0 auto;
  }
  
  .content{
  display: block;
  box-shadow: none;
  margin-bottom:30px;
  }
  
  @media only screen and (max-width: 1169px) {
  .content{
  display: block;
  grid-template-columns: 100%;
  }
  .container {
      display: block;
      padding: 10px 10px;
      margin: 0 auto;
  }
  }
  